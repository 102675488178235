import * as Phaser from 'phaser'
import { MenuButton } from '../ui/menu-button'

const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {
  active: false,
  visible: false,
  key: 'MainMenu'
}

/**
 * The initial scene that starts, shows the splash screens, and loads the necessary assets.
 */
export class MainMenuScene extends Phaser.Scene {
  constructor() {
    super(sceneConfig)
  }

  public create() {
    const centerX = window.innerWidth / 2
    const centerY = window.innerHeight / 2
    this.add
      .text(centerX, centerY - 100, 'TSR TechFest Demo', { fill: '#FFFFFF' })
      .setFontSize(24)
      .setOrigin(0.5)
    new MenuButton(this, centerX, centerY, 'Start', () => {
      this.scene.start('Game')
    }).setOrigin(0.5)
  }
}
