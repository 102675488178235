import * as Phaser from 'phaser'
// import Stats from 'stats.js'
import Scenes from './scenes'

const gameConfig: Phaser.Types.Core.GameConfig = {
  title: 'Sample',

  type: Phaser.AUTO,

  width: window.innerWidth,
  height: window.innerHeight,
  render: {
    antialias: true
  },

  scene: Scenes,

  physics: {
    default: 'arcade',
    arcade: {
      debug: true
    }
  },

  parent: 'game',
  backgroundColor: '#000000'
}

// const stats = new Stats()
// document.body.appendChild(stats.dom)

export const game = new Phaser.Game(gameConfig)

// game.events.on(Phaser.Core.Events.PRE_STEP, () => {
//   stats.begin()
// })

// game.events.on(Phaser.Core.Events.POST_RENDER, () => {
//   stats.end()
// })

// window.addEventListener('resize', () => {
//   game.resize(window.innerWidth, window.innerHeight)
// })
