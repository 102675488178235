import * as posenet from '@tensorflow-models/posenet'

export default class Poser {
  net: posenet.PoseNet
  element: HTMLVideoElement

  async init(element: HTMLVideoElement) {
    this.element = element

    this.net = await posenet.load({
      architecture: 'ResNet50',
      outputStride: 32,
      inputResolution: 161,
      quantBytes: 4
    })
  }

  update = async (element: HTMLVideoElement) => {
    if (this.net) {
      try {
        return await this.net.estimateSinglePose(element, {
          flipHorizontal: true
        })
      } catch (e) {
        console.warn('error')
      }
    }
  }
}
