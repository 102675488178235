import * as Phaser from 'phaser'
import Poser from '../posenet'

const sceneConfig: Phaser.Types.Scenes.SettingsConfig = {
  active: false,
  visible: false,
  key: 'Game'
}

const parts = [
  // 'nose',
  // 'leftEye',
  // 'rightEye',
  // 'leftEar',
  // 'rightEar',
  'leftShoulder',
  'rightShoulder',
  'leftElbow',
  'rightElbow',
  'leftWrist',
  'rightWrist',
  'leftHip',
  'rightHip',
  'leftKnee',
  'rightKnee',
  'leftAnkle',
  'rightAnkle'
]

const joints = [
  'leftShoulder-leftElbow',
  'leftElbow-leftWrist',
  'leftHip-leftKnee',
  'leftKnee-leftAnkle',
  'rightShoulder-rightElbow',
  'rightElbow-rightWrist',
  'rightHip-rightKnee',
  'rightKnee-rightAnkle'
]

interface TargetableGameObject {
  object: Phaser.GameObjects.Ellipse
  targetX: number
  targetY: number
}

export class GameScene extends Phaser.Scene {
  image!: Phaser.GameObjects.Image
  leftArm: Phaser.GameObjects.Rectangle
  poser: Poser
  lastUpdate: number = 0
  parts: Record<string, TargetableGameObject> = {}
  joints: Record<string, Phaser.GameObjects.Line> = {}
  initialPosition = true
  robotBody: Phaser.GameObjects.Quad = null
  robotHead: Phaser.GameObjects.Quad = null
  logo: Phaser.GameObjects.Image = null
  // leesLogo: Phaser.GameObjects.Image = null
  loadingText: Phaser.GameObjects.Text
  constructor() {
    super(sceneConfig)
    this.poser = new Poser()
  }

  public async preload() {
    await this.poser.init(document.getElementById('video') as HTMLVideoElement)
  }

  public create() {
    // this.image = this.add.image(
    //   window.innerWidth / 2,
    //   window.innerHeight / 2,
    //   'sample'
    // )

    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia({
          video: true
        })
        .then(
          stream =>
            ((document.getElementById(
              'video'
            ) as HTMLVideoElement).srcObject = stream)
        )
    }

    const centerX = window.innerWidth / 2
    const centerY = window.innerHeight / 2

    parts.forEach(p => {
      this.parts[p] = {
        object: this.add
          .ellipse(centerX, centerY, 30, 30, 0xe9b846)
          .setDepth(2)
          .setVisible(false),
        targetX: centerX,
        targetY: centerY
      }
    })
    joints.forEach(j => {
      this.joints[j] = this.add.line(0, 0, 0, 0, 0, 0, 0xa17f32).setDepth(1)
      this.joints[j].setLineWidth(5).setVisible(false)
    })

    this.robotBody = this.add.quad(0, 0, 'robotbody').setVisible(false)
    this.robotHead = this.add.quad(0, 0, 'robothead')
    this.robotHead.setScale(0.05)

    this.parts.leftWrist.object.setVisible(false)
    this.parts.rightWrist.object.setVisible(false)
    this.parts.leftAnkle.object.setVisible(false)
    this.parts.rightAnkle.object.setVisible(false)

    this.logo = this.add.image(
      window.innerWidth / 2,
      window.innerHeight - 100,
      'logo'
    )
    this.logo.setScale(0.4)
    this.logo.setDepth(-1000)

    // this.leesLogo = this.add.image(
    //   window.innerWidth / 2,
    //   window.innerHeight - 70,
    //   'leeslogo'
    // )
    // this.leesLogo.setScale(0.5)
    // this.leesLogo.setDepth(-1000)

    this.loadingText = this.add
      .text(window.innerWidth / 2, window.innerHeight / 2 + 100, 'Loading...', {
        fontSize: '40px',
        fontFamily: 'Roboto, helvetica, sans-serif'
      })
      .setOrigin(0.5)
  }

  public async update(time: number, delta: number) {
    // this.image.rotation += 0.001 * delta
    const pose = await (async () => {
      if (time - this.lastUpdate < 100) return null
      // console.log('est')
      this.lastUpdate = time
      return await this.poser.update(document.getElementById(
        'video'
      ) as HTMLVideoElement)
    })()
    if (pose) {
      pose.keypoints.forEach(({ part, position, score }) => {
        const videoHeight = (document.getElementById(
          'video'
        ) as HTMLVideoElement).offsetHeight
        const videoWidth = (document.getElementById(
          'video'
        ) as HTMLVideoElement).offsetWidth
        const gameHeight = window.innerHeight
        const gameWidth = window.innerWidth

        if (parts.includes(part) && score > 0.5) {
          const yScaleFactor = gameHeight / videoHeight

          const xScaleFactor = yScaleFactor
          const xScaleOffset = -((yScaleFactor * videoWidth - gameWidth) / 2)
          // const yScaleOffset = (xScaleFactor * videoHeight - gameHeight) / 2

          const newTargetX = position.x * xScaleFactor + xScaleOffset
          const newTargetY = position.y * yScaleFactor

          if (part === 'leftAnkle') {
            console.log(
              `${videoWidth}x${videoHeight} (${position.x}, ${position.y}), newTargetY: ${newTargetY}`
            )
          }

          // const newTargetX =
          //   (position.x / videoWidth) * limitingDimension + widthDiff / 2
          // const newTargetY =
          //   (position.y / videoHeight) * limitingDimension + heightDiff / 2

          // Don't check if the target position is very far away on the first
          // pose set
          if (this.initialPosition) {
            this.parts[part].object.setVisible(true)
            // if (Math.abs(newTargetX - this.parts[part].targetX) > 300) return
            // if (Math.abs(newTargetY - this.parts[part].targetY) > 300) return
          }
          this.parts[part].targetX = newTargetX
          this.parts[part].targetY = newTargetY
        }

        if (this.initialPosition) {
          this.robotBody.setVisible(true)
          this.loadingText.setVisible(false)
          for (let joint in this.joints) {
            this.joints[joint].setVisible(true)
          }
        }
      })
      // const video = document.getElementById('video') as HTMLVideoElement

      // let self = this

      // video.addEventListener('loadeddata', function() {
      //   console.log('loaded data')

      //   this.play()
      //   self.headTexture.context.drawImage(this, 0, 0)
      //   self.headTexture.refresh()
      // })

      // const textureKey = `frame_${time}`
      // this.textures.addBase64(textureKey, canvas.toDataURL('image/png'))
      // this.robotHead.setTexture(textureKey)
      // this.initialPosition = false
    }

    Object.values(this.parts).forEach(part => {
      const currentX = part.object.x
      const currentY = part.object.y
      part.object.x += (part.targetX - currentX) * 0.2
      part.object.y += (part.targetY - currentY) * 0.2
    })

    for (const joint in this.joints) {
      const [src, dst] = joint.split('-')
      this.joints[joint].setTo(
        this.parts[src].object.x,
        this.parts[src].object.y,
        this.parts[dst].object.x,
        this.parts[dst].object.y
      )
    }

    this.robotBody.setBottomLeft(
      this.parts['leftHip'].object.x - 25,
      this.parts['leftHip'].object.y + 25
    )
    this.robotBody.setBottomRight(
      this.parts['rightHip'].object.x + 25,
      this.parts['rightHip'].object.y + 25
    )
    this.robotBody.setTopLeft(
      this.parts['leftShoulder'].object.x - 25,
      this.parts['leftShoulder'].object.y - 25
    )
    this.robotBody.setTopRight(
      this.parts['rightShoulder'].object.x + 25,
      this.parts['rightShoulder'].object.y - 25
    )
    this.robotHead.setPosition(
      this.parts['rightShoulder'].object.x +
        (this.parts['leftShoulder'].object.x -
          this.parts['rightShoulder'].object.x) /
          2,
      (this.parts['leftShoulder'].object.y +
        this.parts['rightShoulder'].object.y) /
        2 -
        80
    )

    // this.robotHead.setScale(
    //   (this.parts['rightEar'].object.x - this.parts['leftEar'].object.x) / 2000
    // )
  }
}
